import React from 'react';

import { CategoriesHeader, usePrice } from '@fingermarkglobal/cringer.components';
import { ArrowButton } from '../basket-action/';

const Header = ({
  title = null,
  total = null,
  className = '',
  showCategoriesHeader = true,
  onBasketClick = () => {},
  previousAction = null,
  menuAction = null,
  allowPreviousAction = true,
  isLogoEnabled = true,
  isTitleEnabled = true,
  nextAction = null,
  allowNextAction = true,
  nextActionClass = '',
} = {}) => {
  const { formatPrice } = usePrice();
  return (
    <div data-test="header" className={`flex flex-col bg-header ${className}`}>
      {showCategoriesHeader && <CategoriesHeader isItemRounded={false} className="text-white" />}
      {!showCategoriesHeader && (
        <div className={`flex justify-center mb-2 p-4 p-6`}>
          {isLogoEnabled && <div className="w-80 h-32 bg-logo bg-cover bg-no-repeat" />}
        </div>
      )}
      <div className="grid grid-cols-3 gap-4 justify-between bg-nav p-4 p-6">
        <div className="flex justify-start">
          {previousAction && (
            <ArrowButton
              disabled={!allowPreviousAction}
              onClick={previousAction}
              className="mr-6"
              isArrowInverse={true}
              isNextAction={false}
              data-test="button-secondary"
            />
          )}
        </div>
        <div className="self-center">
          {isTitleEnabled && (
            <h1 className="text-5xl font-bold text-center font-header">{title}</h1>
          )}
        </div>
        <div className="flex justify-end space-x-4">
          <p className="text-5xl font-bold self-center text-center break-words">
            {formatPrice({ price: total })}
          </p>
          {nextAction && (
            <ArrowButton
              disabled={!allowNextAction}
              onClick={nextAction}
              className={`ml-6 ${nextActionClass}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { Header };
