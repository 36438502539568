import React from 'react';
import {
  AccessibilityWrapper,
  AccessibilityButton,
  TimeoutModal,
  useTemplate,
} from '@fingermarkglobal/cringer.components';

import { Header } from '../../components/stateless/header';
import { Footer } from '../../components/stateless/footer';

import Standing from '@fingermarkglobal/cringer.components/src/assets/images/icons/accessibility/standing.svg';
import Wheelchair from '@fingermarkglobal/cringer.components/src/assets/images/icons/accessibility/wheelchair.svg';

import Logo from '../../assets/images/HardeesLogo.png';

const LogoImage = () => <img src={Logo} className="w-auto h-64 mt-32 absolute top-0" />;

const CustomDefaultTemplate = ({
  children,
  timeoutEnabled = true,
  footerEnabled = true,
  headerEnabled = true,
  templateOverride = false,
  customBackgroundImage = 'bg-default',
  showAccessibilityButton = true,
  showCategoriesHeader = false,
}) => {
  const {
    header,
    footer,
    modalAccessibilityClass,
    isTimeoutEnabled,
    accessibilityEnabled,
    timeoutActionOverride,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
    footerEnabled,
  });

  return (
    <div className="w-full bottom-0 absolute overflow-y-hidden h-full bg-black">
      <AccessibilityWrapper
        isAccessibilityButtonEnabled={showAccessibilityButton}
        className={customBackgroundImage}
        LogoImage={LogoImage}
      >
        <div className="w-screen flex flex-col h-full w-full">
          {headerEnabled && (
            <Header {...{ ...header, showCategoriesHeader }} isLogoEnabled={true} />
          )}
          <div className="flex-grow overflow-auto">{children}</div>
          {footerEnabled && <Footer {...footer} />}
          {showAccessibilityButton && (
            <div className="flex flex-col justify-center items-start">
              <AccessibilityButton
                AccessibilityIcon={accessibilityEnabled ? Standing : Wheelchair}
                className="mb-14 block w-24 h-24 absolute bottom-0 shadow-md m-4 bg-white"
              />
            </div>
          )}
          {isTimeoutEnabled && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
