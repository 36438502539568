import { getJwtToken } from '@fingermarkglobal/utilities';
import { check } from '@fingermarkglobal/validation';
import { request, HTTPError, TimeoutError } from '@fingermarkglobal/request';
import { buildRequestOptions } from '../helpers';
import { CancelOrderPayload } from '@fingermarkglobal/types'
import { providerRequestFormatter } from '../helpers';
const cancelOrder = async (payload: CancelOrderPayload, timeout: number = 30, ip?: string, provider: string = '') => {
  logger.info('[@fingermarkglobal/fm-api] cancelOrder started');

  try {
    const jwtToken = await getJwtToken();
    const endpoint = providerRequestFormatter(process.env.POI_APP_HAWKE_CANCEL_ORDER_ENDPOINT, provider);
    const localIp = JSON.parse(
      process.env.POI_APP_HAWKE_ORDER_LOCAL_IP || 'false'
    );

    const invalid: string = check({
      name: 'cancelOrder',
      parameters: { payload }
    });

    if (invalid)
      throw new Error(`Fail to validate parameters in 'cancelOrder': ${invalid}`);

    if (!jwtToken)
      throw new Error(`Unable to generate 'jwtToken' in 'cancelOrder'`);

    if (!endpoint) throw new Error(`Invalid 'endpoint' provided in 'cancelOrder'`);

    const { endpoint: formattedEndpoint, params } = buildRequestOptions(
      endpoint,
      payload,
      jwtToken,
      timeout,
      ip,
      localIp
    );

    const response: unknown = await request.post(formattedEndpoint, params).json();

    if (!response)
      throw new Error(
        `Invalid response returned from: ${formattedEndpoint} in 'cancelOrder'`
      );

    logger.info('[@fingermarkglobal/fm-api] cancelOrder finished');

    return response;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;
    logger.error('[@fingermarkglobal/fm-api] Error in cancelOrder:', err);
    throw err;
  }
};

export { cancelOrder };
