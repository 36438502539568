import React, { useMemo } from 'react';

import { PrimaryButton, LocaleSelection } from '@fingermarkglobal/cringer.components';

const PrimaryHome = ({
  languages = [],
  onChangeLanguage = () => {},
  t = () => {},
  isCurrentLang,
  primaryAction,
  secondaryAction,
}) => {
  const secondaryActionClass = useMemo(() => {
    return isCurrentLang('uae') ? 'text-button-large' : 'text-button-large px-20';
  }, [isCurrentLang]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="absolute bottom-0 flex items-center justify-center w-full space-x-12 mb-96">
        {!!primaryAction && (
          <PrimaryButton onClick={primaryAction} className="w-2/5 px-20 py-6 text-button-large">
            {t('homePrimaryAction')}
          </PrimaryButton>
        )}
        {!!secondaryAction && (
          <PrimaryButton
            onClick={secondaryAction}
            className={`w-2/5 py-6 ${secondaryActionClass}`}
            data-test="button-secondary"
          >
            {t('homeSecondaryAction')}
          </PrimaryButton>
        )}
      </div>

      <div
        className="absolute bottom-0 flex flex-col items-center justify-center w-full mb-12 space-y-8"
        data-test="home-footer"
      >
        <p className="text-5xl tracking-wide">{t('languageSelectionTitle')}</p>
        <LocaleSelection
          languages={languages}
          onChangeLanguage={onChangeLanguage}
          className="flex items-center justify-center"
        />
      </div>
    </div>
  );
};

export { PrimaryHome };
