import { request, HTTPError, TimeoutError } from '@fingermarkglobal/request';
import { getJwtToken } from '@fingermarkglobal/utilities';
import { PlaceOrderPayload } from '@fingermarkglobal/types';
import { check, validate } from '@fingermarkglobal/validation';
import { buildRequestOptions, providerRequestFormatter } from '../helpers';

const placeOrder = async (
  payload: PlaceOrderPayload,
  timeout: number = 30,
  ip?: string,
  provider: string = '',
) => {

  logger.info('[@fingermarkglobal/fm-api] placeOrder started');

  try {
    validate({ name: 'doPlaceOrder', parameters: { payload } });

    const endpoint = providerRequestFormatter(
      process.env.POI_APP_HAWKE_PLACE_ORDER_ENDPOINT,
      provider,
    );
    const jwtToken = await getJwtToken();
    const localIp = JSON.parse(process.env.POI_APP_HAWKE_ORDER_LOCAL_IP || 'false');

    const invalid = check({
      name: 'Place-order',
      parameters: { payload },
    });

    if (invalid)
      throw new Error(`[@fingermarkglobal/fm-api] Failed to validate parameters [${invalid}]`);
    if (!endpoint)
      throw new Error(
        `[@fingermarkglobal/fm-api] Failed to get place order endpoint in the environment file with the key 'POI_APP_HAWKE_PLACE_ORDER_ENDPOINT'`,
      );
    if (!jwtToken)
      throw new Error(
        `[@fingermarkglobal/fm-api] Request made successfully, but an empty response was received`,
      );

    const { endpoint: formattedEndpoint, params } = buildRequestOptions(
      endpoint,
      payload,
      jwtToken,
      timeout,
      ip,
      localIp,
    );

    const response: unknown = await request.post(formattedEndpoint, params).json();

    if (!response)
      throw new Error(`[@fingermarkglobal/fm-api] Request failed. Response {${response}}`);

    logger.info('[@fingermarkglobal/fm-api] placeOrder finished');

    return response;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;
    logger.error('[@fingermarkglobal/fm-api] Error in placeOrder:', err);
    throw err;
  }
};

export { placeOrder };
