import React from 'react';

import {
  CancelOrderButton,
  PrimaryButton,
  BasketCount,
  SecondaryButton,
} from '@fingermarkglobal/cringer.components';

import CartIconImage from '../../../assets/images/cart-icon.svg';
const Footer = ({
  nextText = 'Next',
  nextAction = null,
  allowNextAction = true,
  additionalText = 'Confirm',
  additionalAction = null,
  allowAdditionalAction = true,
  className = '',
  enableCancelOrder = false,
  enableCustomCancelOrder = false,
  cancelOrderText = 'Cancel',
  showBasketCount = true,
  total = null,
  count = 0,
  spacer = null,
  onBasketClick = () => {},
} = {}) => {
  return (
    <div data-test="footer">
      <div
        data-test="bottom"
        className={`flex py-8 bg-bottom w-full z-0 justify-end items-center text-white`}
      >
        <div className={`flex py-8 px-4 bg-footer z-0 justify-center ${className}`}>
          {(enableCancelOrder || enableCustomCancelOrder) && (
            <CancelOrderButton Button={PrimaryButton} className="mx-4" data-test="button-secondary">
              {cancelOrderText}
            </CancelOrderButton>
          )}
          <div className="flex items-center">
            {spacer}
            {!!additionalAction && (
              <SecondaryButton
                disabled={!allowAdditionalAction}
                onClick={additionalAction}
                className="mx-4"
              >
                {additionalText}
              </SecondaryButton>
            )}
            {!!nextAction && (
              <PrimaryButton disabled={!allowNextAction} onClick={nextAction} className="mx-4">
                {nextText}
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className="flex justify-start space-x-6 mr-4 p-4">
          {showBasketCount && (
            <BasketCount
              className="ml-8 text-white"
              count={count}
              onClick={onBasketClick}
              Icon={CartIconImage}
            />
          )}
          <div className="font-bold text-4xl ml-8 self-center">{total}</div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
